.cards_item{
    /* margin:0px; */
    min-width: 15vw;
}

.cards_items{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.cards_item_info{
    display: none;
}

.cards_item_pic-wrap::after{
    font-family: monsterrat;
}

.cards h2{
    font-size: 36px;
    font-weight: 700;
    font-family: monsterrat;
}

.partner_wrapper{
    width: 80vw;
}

@media screen and (max-width:1024px) {
    .cards_items{
        grid-template-columns: repeat(2, 1fr);
    }
}