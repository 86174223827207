@font-face{
  font-family: ethnocentric;
  src: url("../src/fonts/ethnocentric_rg.otf")
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: ethnocentric, sans-serif;
}

.button-group{
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin-bottom: 50px; */
    /* box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2); */
}

.button-group > a {
  text-decoration: none;
  outline: none;
}

/* Style the scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888; 
}