:root{
    --primary: #fff;
    --black: #242424;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary{
    background-color: var(--primary);
    color: var(--black);
    border: 3px solid var(--black);
    margin: 20px;
}

.btn--inline{
    background-color: var(--primary);
    color: var(--black);
    border: 3px solid var(--black);
    margin: 20px;
    margin-bottom: 150px;
}

.btn--outline{
    background-color: transparent;
    color: #fff;
    padding: 8px, 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium{
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large{
    padding: 12 26;
    font-size: 1.2em;
    height: 50px;
    width: 250px;
}

.btn--medium:hover, .btn--large:hover{
    background: var(--black);
    color: var(--primary);
    transition: all 0.3s ease-out;
}

@media  screen and (max-width: 900px) {
    .button-group{
        flex-direction: column;
    }
    .button-group > a {
        display: flex;
        justify-content: center;
    }
}