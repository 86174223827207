.services-container {
    background-color: #68d7fcff;
    padding: 80px 20px;
    text-align: center;
  }
  
  .services-container h2 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 40px;
    font-family: monsterrat;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    justify-items: center;
    align-items: center;
  }
  
  .service {
    background-color: #15181dff;
    color:#fff;
    padding: 30px;
    text-align: center;
    border-radius: 25px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    height:150px;
    width: -webkit-fill-available;
    transition: all 0.5s ease-in-out;
  }
  
  .service:hover {
    cursor: pointer;
    color:  #15181dff;
    background-color: #fff;
    transition: all 0.5s ease-in-out;
    height: 500px;
  }

  .fly-out {
    margin-top: 2vh;
    display: none;
    transition: all 0.5s ease-in-out;
    color:  #15181dff;
  }

  .service:hover .fly-out {
    display: block;  
    transition: all 0.5s ease-in-out;
  }
  
  .service i {
    font-size: 48px;
    color: #f37055ff;
    margin-bottom: 20px;
  }
  
  .service h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: cascadia;
  }
  
  .service p {
    font-size: 14px;
    line-height: 1.5;
    font-family: cascadia;
  }

  @media screen and (min-width:20px){
    .service:hover{
      height:600px;
    }
    .services-grid {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
    }
    .service h3 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 15px;
    }
  }

  @media screen and (min-width:370px){
    .service:hover{
      height:450px;
    }
  }

  @media screen and (min-width:470px){
    .service:hover{
      height:350px;
    }
  }

  @media screen and (min-width:961px){
    .service:hover{
      height:500px;
    }
    .services-grid {
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;
    }
  }

  @media screen and (min-width:1025px){
    .service:hover{
      height:350px;
    }
    .services-grid {
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;
    }
  }

  @media screen and (min-width:1441px){
    .service:hover{
      height:300px;
    }
    .services-grid {
      grid-template-columns: repeat(4, 1fr);
      gap: 40px;
    }
  }