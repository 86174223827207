.hero {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
}

.hero-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2); */
    object-fit: contain;
    text-align: center;
}

.hero-container > h1 {
    color: #fff;
    margin-top: 25vh;
    width: 80vw;
    text-align: center;
}

.hero-container > .txt-big {
    font-size: 3em;
}

.hero-container > .txt-normal > p {
    font-size: 1em;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

@media screen and (max-width: 960px) {
    .hero-container > .txt-big {
        font-size: 1.6em;
    }

    .hero-container > .txt-normal > p{
        font-size: 0.6em;
    }
}