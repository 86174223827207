.contact-container {
    background-color: #f8f8f8;
    padding: 80px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .contact-container div {
    width: 50%;
    align-content: center;
  }
  
  .contact-container h2 {
    font-size: 36px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 40px;
    font-family: Monsterrat;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
  }
  
  .contact-form label {
    font-size: 18px;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .contact-form input,
  .contact-form textarea {
    text-align: left;
    font-size: 16px;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: none;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  textarea {
    height: 35vh;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    outline: none;
    box-shadow: 0px 2px 4px rgba(58, 152, 240, 0.4);
  }
  
  .contact-form textarea {
    resize: vertical;
  }
  
  .contact-form button[type="submit"] {
    background-color: #68d7fcff;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    padding: 12px 24px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    font-family: monsterrat;
  }
  
  .contact-form button[type="submit"]:hover {
    background-color: rgb(59, 122, 143);
  }
  
  .contact-form input::placeholder,
  .contact-form textarea::placeholder {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #999999;
  }
  
  @media screen and (max-width: 960px) {
    .contact-container .col h2{
      font-size: larger !important;
    }
    .contact-container div {
        width: 90%;
    }
    .contact-form{
        max-width: 90vw;
    }

    .contact-container {
        flex-direction: column;
    }
  }