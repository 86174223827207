.footer-container p{
    color: white;
    font-family: cascadia;
    font-size: clamp(14px, 1.5vw, 1rem);
    text-align: center;
    margin:10px;
}

.footer-container{
    padding:2rem 0px 1rem 0px;
}