h4 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #9b9b9b;
    line-height: 1.5;
    text-align: center;
    margin-top: 30px;
}

/* ///// inputs /////*/

input:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
    font-size: 0.75em;
    color: #999;
    top: -5px;
    -webkit-transition: all 0.225s ease;
    transition: all 0.225s ease;
}

.styled-input {
    float: left;
    width: 293px;
    margin: 1rem 0;
    position: relative;
    border-radius: 4px;
}

.styled-input label {
    color: #999;
    padding: 1.3rem 30px 1rem 30px;
    position: absolute;
    top: 10px;
    left: 0;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    pointer-events: none;
}

.styled-input.wide { 
    width: 650px;
    max-width: 100%;
}

input,
textarea {
    padding: 30px;
    border: 0;
    width: 100%;
    font-size: 1rem;
    background-color: #2d2d2d;
    color: white;
    border-radius: 4px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

}

input:focus,
textarea:focus { outline: 0; }

input:focus ~ span,
textarea:focus ~ span {
    width: 100%;
    -webkit-transition: all 0.075s ease;
    transition: all 0.075s ease;
}

textarea {
    width: 100%;
    min-height: 15em;
    resize: none;
}

.input-container {
    width: 650px;
    max-width: 100%;
    margin: 20px auto 25px auto;
}

.submit-btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: var(--primary);
    color: var(--black);
    border: 3px solid var(--black);
    font-family: ethnocentric, sans-serif;
}

.submit-btn:hover {
    background: var(--black);
    color: var(--primary);
    transition: all 0.3s ease-out;
}

@media screen and (max-width: 960px) {
    .styled-input.wide{
        float: none;
        width: 80vw;
    }
    .submit-btn{
        width: 80vw;
    }
    .col-xs-12{
        justify-content: center;
        display: flex;
    }
}

input[type=checkbox] + label {
  color: #ccc;
  font-style: italic;
} 

input[type=checkbox]:checked + label {
  color: #f00;
  font-style: normal;
}