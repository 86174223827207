.container {
    display: flex;
    flex-direction: row;
    z-index: 0;
  }
  
  .column {
    padding: 2rem;
  }
  
  .left-column {
    background-color: #f2f2f2;
    padding-right: 20px;
    width: 65%;
  }
  
  .right-column {
    background-color: #f2f2f2;
    width: 35%;
    display: grid;
    grid-gap: 10px;
    align-items: center; 
  }
  
  .text {
    font-size: 1.2rem;
    line-height: 2;
    margin-bottom: 5rem;
    margin-left: 15rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-right: 2px solid #ccc;
    padding-right: 10rem;
  }

  h1 {   
    margin-top: 5rem;
    margin-bottom: 2.5rem;
    text-align: left;
  }

  .image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    margin-top: 5rem;
  }
  
  .image-grid img {
    width: 100%;
    object-fit: cover;
    background-color: hsla(0, 0%, 80%, 0.603);
    border-radius: 15px;
  } 

  .image-grid img:hover {
    box-shadow: 0 0 15px #38966f;
    transition: all 1 ease-in-out;
  } 

  .inactive {
    cursor: default;
    box-shadow: none !important;
    transform: none !important;
  }

  .slanted-border {
    position: relative;
    height: 10px;
    background-color: #f2f2f2;
  }
  
  .slanted-border:before,
  .slanted-border:after {
    content: "";
    position: absolute;
    top: 130px;
    left: 0;
    right: 0;
    height: 550px;
    background-color: #f2f2f2;
    z-index: -1;
  }

  @media screen and (max-width:1440px){
    .image-grid{
        grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width:1024px){
    .container {
        flex-direction: column;
    }
    .column {
        width: 100%;
    }
    .image-grid{
        grid-template-columns: repeat(4, 1fr);
        margin-top: 0px;
    }

    .text {
        margin-left: 5rem;
        border: none;
        margin-bottom: 0px;
    }
  }

  @media screen and (max-width:425px) {
    .text {
        margin-left: 0rem;
        padding-right: 0rem;
    }
    .image-grid{
        grid-template-columns: repeat(2, 1fr);
    }
  }

  .slanted-border:before {
    transform: skewY(10deg);
    transform-origin: right;
  }
  
  .slanted-border:after {
    transform-origin: left;
    transform: skewY(-10deg);
  }