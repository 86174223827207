.no-render{
  display: none !important;
}

.navbar {
    background-color: black;
    height: 80px;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    left: 0;
    width: 500px;
    transition: all 0.3s ease-in-out ;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    transition: all 0.5s ease-in-out ;
  }
  
  .nav-links:hover {
    border-radius: 5px;
    color: #38966f;
    background-color: #fff;
    font-weight: bolder;
    transition: all 0.3s ease-in-out ;
  }
  
  .menu-icon {
    display: none;
  }
  
@media screen and (max-width: 600px){
    .navbar-logo{
        font-size: 1em;
        transition: all 0.5s ease;
    }
}

@media screen and (max-width: 320px){
  .navbar-logo{
      font-size: 0.8em;
      transition: all 0.5s ease;
  }
}

  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 60px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      margin-top: 20px;
      background: rgba(0,0,0,0.5);
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      height: 50px;
      text-align: center;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    /* .navbar-logo {
      position: absolute;
      top: 0;
      transform: translate(25%, 50%);
    } */
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  }

  .navbar-logo:hover {
    text-shadow: 0 0 30px #fff;
    font-size: 1.8rem;
    transition: all 0.3s ease-in-out;
  }