#about {
    background-color: #68d7fcff;
    padding: 80px 20px;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

#about p  {
    margin-top: 2vh;
    font-family: cascadia;
}

#about img{
    max-height: 50vh;
}

.about-text {
    margin-right: 15vw;
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:430px) {
    .icon-container{
        display: none;
    }
    #about {
        display: flex;
        flex-direction: column;
    }
    .about-text {
        margin-right: 0vw;
    }
}