.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  .header-container .tag {
    font-family: cascadia;
    font-size: 16px;
    font-weight: 400;
    color: #d1d1d1ff;
    text-decoration: none;
  }

  .header-container .nav-menu{
    width: 50vw;
  }
  
  /* Logo Container */
  .header-container  .logo-container {
    display: flex;
    align-items: center;
  }

  .header-container  .menu-icon {
    display: none;
  }
  
  /* Logo */
  .header-container .logo {
    height: 15vh;
    margin-right: 20px;
  }
  
  /* Navigation Menu */
  .header-container .nav-menu ul {
    display: flex;
    list-style: none;
  }
  
  /* Navigation Links */
  .header-container .nav-menu li {
    margin: 0 10px;
  }
  
  .header-container .nav-menu li a {
    font-family: cascadia;
    font-size: 16px;
    font-weight: 400;
    color: #68d7fcff;
    text-decoration: none;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .header-container .nav-menu li a:hover {
    color: #fff;
    text-decoration: underline;
    transition: all .5s ease-in-out;
  }

  @media screen and (max-width: 1440px){
    .header-container .logo {
      height: 5vh;
    }
  }

  @media screen and (max-width: 960px) {
    .header-container .NavbarItems {
      position: relative;
    }

    .tag {
      display: none;
    }

    .header-container .nav-menu ul {
      flex-direction: column;
    }
  
    .header-container .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 60px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .header-container .nav-menu.active {
      margin-top: 5vh;
      background: rgba(0,0,0);
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 2;
    }
  
    .header-container .nav-links {
      height: 50px;
      text-align: center;
      width: 100%;
      display: table;
    }
  
    .header-container .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .header-container .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }

    .header-container .nav-menu li a {
      font-size: 24px;
    }
  }

