.about-container p {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin: 2.5vh 0px;
}

.about-container h4 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bolder;
    margin-bottom: 5vh;
}

.about-container {
    display: block;
    padding: 5vh 20vw 0px 20vw;
}