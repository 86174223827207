.cards {
    padding-top: 4rem;
    background: #fff;
  }
  
  h1 {
    text-align: center;
  }
  
  .cards_container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
    
  }

  .cards_container > h2 {
    text-align: center;
  }
  
  .cards_wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards_items {
    margin-bottom: 24px;
  }
  
  .cards_item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
    min-width: 25vw;
  }

  .cards_item_wide {
    width: 75vw;
    height: 50vh;
    margin-bottom: 20px;
  }
  
  .cards_item_link {
    display: flex;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }

  .cards_item_link_column{
    flex-flow: column;
  }

  .cards_item_link_row{
    flex-flow: row;
  }
  
  .cards_item_pic-wrap, .cards_item_pic-wrap_out {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }

  .cards_item_pic-wrap_wide {
    padding-top: 67%;
    width: 50vw;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards_item_pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    width: 80%;
    font-weight: 700;
    font-size: .6em;
    color: #fff;
    background-color: #38966f;
    box-sizing: border-box;
  }
  
  .cards_item_img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards_item_img_wide {
    position: absolute;
    top: 20px;
    right: 0;
    bottom: 0;
    left: 20px;
    display: block;
    height: 90%;
    max-height: 90%;
    max-width: 25vw;
    border-radius: 15px;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards_item_img:hover {
    transform: scale(1.1);
  }
  
  .cards_item_info {
    padding: 20px 30px 30px;
    width: 100%;
    overflow-y: auto;
  }
  
  .cards_item_text {
    color: #252e48;
    font-size: 1em;
    line-height: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .cards_item_title {
    color: #252e48;
    font-size: 1.5em;
    line-height: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .cards_item_title-sub {
    color: #38966f;
    font-size: 1.2em;
    line-height: 30px;
    font-style: oblique;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .cards_item_alinea{
    margin-top: 20px;
  }
  
  @media only screen and (min-width: 1200px) {
    .content_blog_container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards_items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards_item {
      margin-bottom: 2rem;
    }
    .cards_item_pic-wrap::after{
      font-size: .6em;
    }
  }