.heroimage_container{
    color: #fff;
    font-family: cascadia;
    padding: 10vh 0px 0px 10vw;
    margin-bottom: 150px;
    display: flex;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px; 
}

.herotext{
    width: 50vw;
}

.heroimage{
    width: 50vw;
}

.heroimage img{
    width: 50vw;
}

.heroimage_container p, .heroimage_container h1 {
    font-family: cascadia;
    margin-bottom: 5vh;
    margin-top: 0px;
}

.heroimage_container button {
    font-family: cascadia;
    color: #fff;
    background-color: #14acffff;
    font-size: large;
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 5vh;
    display: inline-block;
}

.call-button a {
    font-family: cascadia;
}

.heroimage_container button:hover{
    transform: scale(1.1);
    background-color: #0d90d7ff;
}

@media screen and (max-width:750px) {
    .heroimage{
        display: none;
    }
    .herotext{
        width: 100vw;
    }
    
    .heroimage{
        width: 100vw;
    }
    
    .heroimage img{
        width: 100vw;
    }
    .heroimage_container{
        padding: 10vh 10vw 0px 10vw;
    }
}