.script-container{
    background-color: #15181dff;
    font-family: cascadia, monospace;
}

@font-face {
    font-family: cascadia;
    src: url('../proto/proto_fonts/Cascadia.otf');
}

@font-face {
    font-family: monsterrat;
    src: url('../proto/proto_fonts/Montserrat.ttf');
}